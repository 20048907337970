import {
  BrandsApi,
  Configuration,
  CreateOrg201Response,
  GetAllOrgs200Response,
  GetBrandsByOrgId200Response,
  Org,
  OrgsApi,
} from '@flipdish/orgmanagement';

import { getApiEndPoint } from '../../helpers/apibase';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const baseURL = getApiEndPoint();

const configuration = new Configuration({
  basePath: baseURL,
  useDefaultUserAgent: true,
});

const defaultOptions = {
  withCredentials: true,
};

const orgsApi = new OrgsApi(configuration);
const brandsApi = new BrandsApi(configuration);
// #region getBrandsForOrg
export const getBrandsForOrgKey = 'getBrandsForOrg';
export async function getBrandsForOrg(
  orgId: string
): Promise<{ data: GetBrandsByOrgId200Response }> {
  try {
    return await brandsApi.getBrandsByOrgId({ orgId }, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getBrands
export const getOrgByBrandIdKey = 'getOrgByBrandId';
export async function getOrgByBrandId(brandId: string): Promise<{ data: GetAllOrgs200Response }> {
  try {
    return await orgsApi.getAllOrgs({ brandId }, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getOrg
export const getOrgKey = 'getOrg';
export async function getOrg(orgId: string): Promise<{ data: CreateOrg201Response }> {
  try {
    return await orgsApi.getOrgById({ orgId }, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getOrgs
export const getOrgsKey = 'getOrgs';
export async function getOrgs(): Promise<{ data: GetAllOrgs200Response }> {
  try {
    return await orgsApi.getAllOrgs({}, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region createOrg
export const createOrgKey = 'createOrg';
export async function createOrg(org: Org): Promise<{ data: CreateOrg201Response }> {
  try {
    return await orgsApi.createOrg({ createOrg: org }, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion
const organisationService = {
  getBrandsForOrg,
  getOrgByBrandId,
  getOrg,
  createOrg,
};

export default organisationService;
