import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import { useHistory, useLocation } from 'react-router-dom';

const StyledTabButton = styled(Tab)`
  margin-right: ${({ theme }) => theme.spacing(3)};
  padding: 0;
  min-width: 0;
`;

const StyledTabContentContainer = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const StyledTabBarContainer = styled(Grid)`
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
  ${(props) => props.theme.breakpoints.down('md')} {
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

type TabProps = {
  tabItems: Array<{
    label: string;
    component: JSX.Element;
    queryString: string;
  }>;
};
const Tabs = (props: TabProps) => {
  const { tabItems } = props;
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const initialTab = params.get('tab');

  const getInitalTabIndex = () => {
    if (!initialTab) {
      return 0;
    }

    // Find index of tab with queryString equal to tabString
    const index = tabItems?.findIndex((tab) => tab?.queryString === initialTab);
    if (index >= 0) {
      return index;
    }

    return 0;
  };
  const [tabIndex, setTabIndex] = useState(getInitalTabIndex());

  const handleTabChanged = (selectedIndex: number) => {
    setTabIndex(selectedIndex);
    const queryString = tabItems?.[selectedIndex]?.queryString;
    params.set('tab', queryString);

    history.replace({ search: params.toString() });
  };

  return (
    <Grid container item xs={12}>
      <StyledTabBarContainer item xs={12}>
        <MuiTabs
          value={tabIndex}
          onChange={(_, index) => handleTabChanged(index)}
          indicatorColor="primary"
          id="payout-report-tabs"
        >
          {tabItems?.map((tab) => (
            <StyledTabButton data-fd={`${tab.label}-tab`} label={tab.label} key={tab.label} />
          ))}
        </MuiTabs>
      </StyledTabBarContainer>
      <StyledTabContentContainer item xs={12}>
        {tabItems?.[tabIndex]?.component || <></>}
      </StyledTabContentContainer>
    </Grid>
  );
};

export default Tabs;
