import { AppsApiFactory } from '@flipdish/api-client-typescript';
import axios from 'axios';

import { getApiEndPoint } from '../helpers/apibase';
import { createApi } from '../helpers/utilities';
import { mapServerError } from './utils/serverErrorMapper';

const baseURL = getApiEndPoint();
const appsApi = createApi(AppsApiFactory);

const newAppsApi = axios.create({
  baseURL: `${baseURL}/api/v1.0`,
  withCredentials: true,
});

const getApps = async (query?: string, page?: number, limit?: number) => {
  try {
    const incomingMessage = await appsApi.getApps(query, page, limit);
    return incomingMessage;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
};

const getApp = async (appId: string) => {
  try {
    const incomingMessage = await appsApi.getApp(appId);
    return incomingMessage?.Data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
};

const getSupportApps = async (query?: string, page?: number, limit?: number) => {
  try {
    const result = await newAppsApi.get(
      `apps/support?page=${page}&limit=${limit}&nameFilter=${query}`
    );
    return result.data;
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
};

const startAppSupport = async (appId: string) => {
  try {
    await newAppsApi.post(`apps/support/start`, { AppId: appId });
    return { AppId: appId };
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
};

const createApp = async (appName?: string) => {
  try {
    const app = await appsApi.createApp({ Name: appName });
    return { name: app.Data };
  } catch (incomingMessage) {
    const e = await mapServerError(incomingMessage);
    throw e;
  }
};

export const appsService = { createApp, getApp, getApps, getSupportApps, startAppSupport };
